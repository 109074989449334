const checkGudang = (dataDetail) => {
	if (dataDetail.length > 0) {
		for (let i = 0; i < dataDetail.length; i++) {
			if (dataDetail[i].id_gudang === null) {
				return false
			}
		}
	}

	return true
}

export default checkGudang