// React
import React, {useState, useEffect} from 'react'

// Form
import { Formik } from 'formik'

// Components
import { 
	FilterModal, DatePicker, SelectSearch 
} from 'components'
import { DateConvert } from 'utilities'

// API
import Axios from "axios"
import { SalesOrderSPKApi } from 'api'

const ModalFilter = ({ show, setShow, data, setData }) => {
	// STATE DATA
	const [dataCustomer, setDataCustomer] = useState([{label: 'Semua', value: undefined}])
	const [dataProyek, setDataProyek] = useState([{label: 'Semua', value: undefined}])
	const dataStatusApproval = [
		{
			label: 'Semua',
			value: undefined
		},
		{
			label: 'APPROVED',
			value: 'APP'
		},
		{
			label: 'VERIFIED',
			value: 'VER'
		},
		{
			label: 'REVISI',
			value: 'REV'
		},
		{
			label: 'REJECT',
			value: 'REJ'
		},
		{
			label: 'PENDING',
			value: 'PEN'
		}
	]
	const dataPeringkatPeluang = [
		{
			label: 'Semua',
			value: undefined
		},
		{
			label: 'Urgent',
			value: 'Urgent'
		},
		{
			label: 'Normal',
			value: 'Normal'
		}
	]
	const [loading, setLoading] = useState(true)

	// REQUSET DATA SERVER
	const getDataDropdown = () => {
		Axios.all([
			SalesOrderSPKApi.getDropdownCustomer(),
			SalesOrderSPKApi.getDropdownProyek(),
		])
		.then(Axios.spread((customer, proyek) => {
			const mapDataCustomer = customer?.data?.data ? customer.data.data.map(val => ({label: val?.nama_customer, value: val?.id_customer})) : []
			const mapDataProyek = proyek?.data?.data ? proyek.data.data.map(val => ({label: val?.nama_proyek, value: val?.id_proyek})) : []

			setDataCustomer([...dataCustomer, ...mapDataCustomer])
			setDataProyek([...dataProyek, ...mapDataProyek])
		}))
		.finally(() => setLoading(false))
	}

	// USE EFFECT ALL DROPDOWN
    useEffect(() => {
		getDataDropdown()

		// eslint-disable-next-line
    }, [])

	// FORM VALUES
	const formInitialValues = {
		tgl_sales_order_mulai: data.tgl_sales_order_mulai,
		tgl_sales_order_selesai: data.tgl_sales_order_selesai,
		tgl_rab_mulai: data.tgl_rab_mulai,
		tgl_rab_selesai: data.tgl_rab_selesai,
		customer: data.customer,
		proyek: data.proyek,
		peringkat_peluang: data.peringkat_peluang,
		status_approval: data.status_approval,
	}
	const formSubmitHandler = (values) => {
		const checkActive = !!Object.values(values).filter(val => val !== undefined).length > 0

		setData({
			...data,
			...values,
			active: checkActive,
			page: 1
		})

		setShow(false)
	}

	// HANDLE CHANGE 
	const onTanggalSOChange = (dates, values, setValues) => {
		const [start, end] = dates
		const startDate = start ? DateConvert(start).default : null
		const endDate = end ? DateConvert(end).default : null

		setValues({
			...values,
			tgl_sales_order_mulai: startDate,
			tgl_sales_order_selesai: endDate,
		})
	}
	const onTanggalRABChange = (dates, values, setValues) => {
		const [start, end] = dates
		const startDate = start ? DateConvert(start).default : null
		const endDate = end ? DateConvert(end).default : null

		setValues({
			...values,
			tgl_rab_mulai: startDate,
			tgl_rab_selesai: endDate,
		})
	}
	const onResetButtonClick = (values, setValues) => {
		setValues({
			tgl_sales_order_mulai: undefined,
			tgl_sales_order_selesai: undefined,
			tgl_rab_mulai: undefined,
			tgl_rab_selesai: undefined,
			customer: undefined,
			proyek: undefined,
			peringkat_peluang: undefined,
			status_approval: undefined,
		})
	}

	return (
		<Formik
			enableReinitialize
			initialValues={formInitialValues}
			onSubmit={formSubmitHandler}
		>	
			{({values, handleSubmit, setFieldValue, setValues}) => (
				<FilterModal
					show={show}
					setShow={setShow}
					onResetButtonClick={() => onResetButtonClick(values, setValues)}
					onFilterButtonClick={handleSubmit}
				>		
					<DatePicker 
						selectsRange
						label="Tgl. Sales Order"
						placeholderText="Pilih Tanggal Sales Order"
						startDate={values.tgl_sales_order_mulai ? new Date(values.tgl_sales_order_mulai) : ''}
						endDate={values.tgl_sales_order_selesai ? new Date(values.tgl_sales_order_selesai) : ''}
						onChange={dates => onTanggalSOChange(dates, values, setValues)}
						monthsShown={2}
					/>

					<DatePicker 
						selectsRange
						label="Tgl. RAB"
						placeholderText="Pilih Tanggal RAB"
						startDate={values.tgl_rab_mulai ? new Date(values.tgl_rab_mulai) : ''}
						endDate={values.tgl_rab_selesai ? new Date(values.tgl_rab_selesai) : ''}
						onChange={dates => onTanggalRABChange(dates, values, setValues)}
						monthsShown={2}
					/>

					<SelectSearch 
						key={values.customer}
						label="Customer"
						placeholder="Pilih customer"
						defaultValue={dataCustomer.find(val => val.value === values.customer)}
						option={dataCustomer}
						onChange={val => setFieldValue('customer', val.value)}
						loading={loading}
					/>

					<SelectSearch 
						key={values.proyek}
						label="Proyek"
						placeholder="Pilih Proyek"
						defaultValue={dataProyek.find(val => val.value === values.proyek)}
						option={dataProyek}
						onChange={val => setFieldValue('proyek', val.value)}
						loading={loading}
					/>

					<SelectSearch
						key={values.peringkat_peluang}
						label="Peringkat Peluang"
						placeholder="Pilih Peringkat Peluang"
						defaultValue={dataPeringkatPeluang.find(val => val.value === values.peringkat_peluang)}
						option={dataPeringkatPeluang}
						onChange={val => setFieldValue('peringkat_peluang', val.value)}
						loading={loading}
					/>

					<SelectSearch
						key={values.status_approval}
						label="Status Approval"
						placeholder="Pilih Status Approval"
						defaultValue={dataStatusApproval.find(val => val.value === values.status_approval)}
						option={dataStatusApproval}
						onChange={val => setFieldValue('status_approval', val.value)}
						loading={loading}
					/>
				</FilterModal>
			)}
		</Formik>
	)
}
export default ModalFilter