import React, {useState, useEffect} from 'react'
import { Formik } from 'formik'
import {
    FilterModal,
    DatePicker,
    SelectSearch
} from 'components'
import { DateConvert } from 'utilities'
import Axios from "axios"
import { SalesOrderSPKApi } from "api";

const ModalFilter = ({ show, setShow, data, setData }) => {
// STATE DATA
    const [dataCustomer, setDataCustomer] = useState([{label: 'Semua', value: undefined}])
    const [dataProyek, setDataProyek] = useState([{label: 'Semua', value: undefined}])
    const [dataBaseline, setDataBaseline] = useState([
        {
            label: 'Semua',
            value: undefined
        },
        {
            label: '1',
            value: '1'
        },
        {
            label: '2',
            value: '2'
        },
        {
            label: '3',
            value: '3'
        },
        {
            label: '4',
            value: '4'
        },
        {
            label: '5',
            value: '5'
        },
        {
            label: '6',
            value: '6'
        },
        {
            label: '7',
            value: '7'
        },
        {
            label: '8',
            value: '8'
        },
        {
            label: '9',
            value: '9'
        },
        {
            label: '10',
            value: '10'
        },
    ])
    const [loading, setLoading] = useState(true)
// REQUSET DATA SERVER
    const getDataDropdown = () => {
        Axios.all([
        SalesOrderSPKApi.getDropdownCustomer(),
        SalesOrderSPKApi.getDropdownProyek(),
        SalesOrderSPKApi.getDropdownUnitProduksi(),
        ])
        .then(Axios.spread((customer, proyek, unit) => {
            const mapDataCustomer = customer?.data?.data ? customer.data.data.map(val => ({label: val?.nama_customer, value: val?.id_customer})) : []
            const mapDataProyek = proyek?.data?.data ? proyek.data.data.map(val => ({label: val?.nama_proyek, value: val?.id_proyek})) : []

            setDataCustomer([...dataCustomer, ...mapDataCustomer])
            setDataProyek([...dataProyek, ...mapDataProyek])
        }))
        .finally(() => setLoading(false))
    }
// USE EFFECT ALL DROPDOWN
    useEffect(() => {
        getDataDropdown()
    }, [])
// FORM VALUES
    const formInitialValues = {
        tgl_spk_mulai: data?.filter?.tgl_spk_mulai,
        tgl_spk_selesai: data?.filter?.tgl_spk_selesai,
        tgl_penawaran_mulai: data?.filter?.tgl_penawaran_mulai,
        tgl_penawaran_selesai: data?.filter?.tgl_penawaran_selesai,
        customer: data?.filter?.customer,
        baseline: data?.filter?.baseline,
    }
    const formSubmitHandler = (values) => {
        const checkActive = !!Object.values(values).filter(val => val !== undefined).length > 0

        if (checkActive) {
          setData({ 
              filter: {
                  ...values,
                  active: true
              },
              pagination: {
                  ...data.pagination,
                  page: 1
              }
           })
        } else { 
            setData({ 
                ...data,
                filter: {
                    ...values,
                    active: false
                }
             })
        }
    
        setShow(false)
    }
// HANDLE CHANGE 
    const onTanggalSPKChange = (dates, values, setValues) => {
        const [start, end] = dates
        const startDate = start ? DateConvert(start).default : null
        const endDate = end ? DateConvert(end).default : null

        setValues({
            ...values,
            tgl_spk_mulai: startDate,
            tgl_spk_selesai: endDate,
        })
    }
    const onTanggalPenawaranChange = (dates, values, setValues) => {
        const [start, end] = dates
        const startDate = start ? DateConvert(start).default : null
        const endDate = end ? DateConvert(end).default : null

        setValues({
            ...values,
            tgl_penawaran_mulai: startDate,
            tgl_penawaran_selesai: endDate,
        })
    }
    const onResetButtonClick = (values, setValues) => {
        setValues({
            tgl_spk_mulai: undefined,
            tgl_spk_selesai: undefined,
            tgl_penawaran_mulai: undefined,
            tgl_penawaran_selesai: undefined,
            customer: undefined,
            baseline: undefined
        })
    }

    return (
        <Formik
            enableReinitialize
            initialValues={formInitialValues}
            onSubmit={formSubmitHandler}>
            
            {({values, handleSubmit, setFieldValue, setValues}) => (
                <FilterModal
                    show={show}
                    setShow={setShow}
                    onResetButtonClick={() => onResetButtonClick(values, setValues)}
                    onFilterButtonClick={handleSubmit}>
                    
                    <DatePicker 
                        selectsRange
                        label="Tgl. SPK"
                        placeholderText="Pilih Tanggal SPK"
                        startDate={values.tgl_spk_mulai ? new Date(values.tgl_spk_mulai) : ''}
                        endDate={values.tgl_spk_selesai ? new Date(values.tgl_spk_selesai) : ''}
                        onChange={dates => onTanggalSPKChange(dates, values, setValues)}
                        monthsShown={2}
                    />

                    <DatePicker 
                        selectsRange
                        label="Tgl. Penawaran"
                        placeholderText="Pilih Tanggal Penawaran"
                        startDate={values.tgl_penawaran_mulai ? new Date(values.tgl_penawaran_mulai) : ''}
                        endDate={values.tgl_penawaran_selesai ? new Date(values.tgl_penawaran_selesai) : ''}
                        onChange={dates => onTanggalPenawaranChange(dates, values, setValues)}
                        monthsShown={2}
                    />

                    <SelectSearch 
                        key={values.customer}
                        label="Customer"
                        placeholder="Pilih customer"
                        defaultValue={dataCustomer.find(val => val.value === values.customer)}
                        option={dataCustomer}
                        onChange={val => setFieldValue('customer', val.value)}
                        loading={loading}
                    />

                    <SelectSearch
                        key={values.baseline}
                        label="Baseline"
                        placeholder="Pilih Baseline"
                        defaultValue={dataBaseline.find(val => val.value === values.baseline)}
                        option={dataBaseline}
                        onChange={val => setFieldValue('baseline', val.value)}
                        loading={loading}
                    />
                </FilterModal>
            )}
        </Formik>
    )
}
export default ModalFilter;