import Services from "services";

class LaporanPenjualanApi {

  getPage(params) {
    return Services.get("/laporan_penjualan", { params });
  }

  getDropdown(params) {
    return Services.get("/laporan_penjualan/dropdown", { params });
  }

  getExport(params) {
    return Services.get("/penjualan_report", { params });
  }
}

export default new LaporanPenjualanApi();