import React from "react";
import { Table, Tr, Td } from "components";
import { RupiahConvert } from "utilities";

const KreditLimit = ({ data, diskon, ppn }) => {
  const Calculate = () => {
    const profit = data.profit ?? 0;
    const akumulasi = data.akumulasi ?? 0;

    const subTotal = data?.detail?.map((res, i) => {
      const sum =
        parseFloat(res.rounded ?? 0) * parseFloat(res.qty_rab ?? res.qty_sales_order ?? 0);
      return sum;
    });

    const total = subTotal?.reduce((prev, curr) => prev + parseFloat(curr ?? 0), 0);

    const totalSetelahDiskon = parseFloat(total ?? 0) - parseInt((total * diskon) / 100);

    const totalSetelahPajak = totalSetelahDiskon + parseInt((totalSetelahDiskon * ppn) / 100);

    const totalDenganAkumulasi = parseInt(totalSetelahPajak) + parseInt(akumulasi);

    return totalDenganAkumulasi;
  };

  return (
    <>
      <small>Kredit Limit</small>
      <br />

      <Table>
        <Tr>
          <Td textRight>
            <b>Akumulasi Piutang Customer</b>
          </Td>
          <Td textRight>{RupiahConvert(parseFloat(data.akumulasi ?? 0).toString()).getWithComa}</Td>
        </Tr>
        <Tr>
          <Td textRight>
            <b>Akumulasi Piutang Customer + Total Sales Order</b>
          </Td>
          <Td textRight>{RupiahConvert(parseFloat(Calculate()).toString()).getWithComa}</Td>
        </Tr>
        <Tr>
          <Td textRight>
            <b>Kredit Limit Customer</b>
          </Td>
          <Td textRight>
            {RupiahConvert(parseFloat(data.kredit_limit ?? 0).toString()).getWithComa}
          </Td>
        </Tr>
        <Tr>
          <Td textRight colSpan={2}>
            {Calculate() >= data.kredit_limit ? (
              <b style={{ color: "red" }}>TRANSAKSI MELEBIHI KREDIT LIMIT</b>
            ) : (
              <b style={{ color: "green" }}>TRANSAKSI DIBAWAH KREDIT LIMIT</b>
            )}
          </Td>
        </Tr>
      </Table>
    </>
  );
};

export default KreditLimit;
