import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Row, Col, Card } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { Alert, BackButton, DataStatus, ActionButton } from "components";
import { DateConvert } from "utilities";
import { FakturPenjualanApi } from "api";
import { InfoSection, FormSection, TableSection } from "./Section";

const UbahFakturPenjualan = ({ setNavbarTitle }) => {
  const { id } = useParams();
  const history = useHistory();
  const [dataInfo, setDataInfo] = useState({});
  const [dataTable, setDataTable] = useState([]);
  const [dataStakeholder, setDataStakeholder] = useState([]);
  const [formRendered, setFormRendered] = useState(0);
  const [fetchingStatus, setFetchingStatus] = useState({
    loading: false,
    success: true,
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  const mappingDataInfo = (data) => {
    return {
      id_faktur_penjualan: data.id_faktur_penjualan ?? null,
      tgl_faktur_penjualan: data.tgl_faktur ?? null,
      no_faktur_penjualan: data.no_faktur ?? "-",
      id_sales_order: data.id_sales_order ?? null,
      tgl_sales_order: data.tgl_sales_order ?? null,
      no_sales_order: data.no_sales_order ?? "-",
      customer: data.nama_customer ?? "-",
      sales: data.nama_sales ?? "-",
      diskon: data.diskon ? parseFloat(data.diskon) : 0,
      ppn: data.ppn ? parseFloat(data.ppn) : 0,
      nama_proyek: data.nama_proyek ?? "-",
      nama_unit_produksi: data.nama_unit_produksi
    };
  };

  const mappingDataTable = (data) =>
    data.map((val) => {
      return {
        id_surat_jalan: val.id_surat_jalan ?? null,
        tgl_surat_jalan: val.tgl_surat_jalan ?? null,
        no_surat_jalan: val.no_surat_jalan ?? "-",
        no_delivery_order: val.no_delivery_order ?? "-",
        nama_item: val.nama_item ?? "-",
        satuan: val.nama_satuan ?? "",
        qty: val.qty ? parseFloat(val.qty) : 0,
        harga: val.harga_satuan_jual ? parseFloat(val.harga_satuan_jual) : 0,
        checked: false,
      };
    });

  const getInitialData = () => {
    setFetchingStatus({
      loading: true,
      success: false,
    });

    FakturPenjualanApi.getSingleFaktur({ id_faktur_penjualan: id })
      .then(({ data }) => {
        const getData = data.data;
        const getDataStakeholder = data.data.stakeholder;
        const mapDataInfo = mappingDataInfo(getData ?? {});
        const mapDataTable = mappingDataTable(getData.detail ?? []);

        setDataInfo(mapDataInfo);
        setDataTable(mapDataTable);
        setDataStakeholder(getDataStakeholder);
        setFetchingStatus({
          loading: false,
          success: true,
        });
      })
      .catch(() => {
        setAlertConfig({
          show: false,
          variant: "primary",
          text: "Data gagal dimuat!",
        });
        setFetchingStatus({
          loading: false,
          success: false,
        });
      });
  };

  const formInitialValues = {
    id_sales_order: dataInfo.id_sales_order,
    tanggal: dataInfo.tgl_faktur_penjualan,
    nomor: dataInfo.no_faktur_penjualan,
    diskon: dataInfo.diskon,
    ppn: dataInfo.ppn,
  };

  const formValidationSchema = Yup.object().shape({
    tanggal: Yup.string().required("Pilih tanggal"),
    nomor: Yup.string().required("Pilih tanggal untuk menentukan nomor"),
  });

  const formSubmitHandler = (values, { setSubmitting }) => {
    const finalValues = {
      id_faktur_penjualan: id,
      id_sales_order: values.id_sales_order,
      tgl_faktur: values.tanggal,
      ppn: values.ppn,
      diskon: values.diskon,
    };

    FakturPenjualanApi.update(finalValues)
      .then(() => {
        setAlertConfig({
          show: true,
          variant: "primary",
          text: "Data berhasil diubah!",
        });
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal diubah!",
        });
      })
      .finally(() => setSubmitting(false));
  };

  const CatatanApprovalCard = () => {
    const InfoItem = ({ title1, value1, title2, value2 }) => (
      <div className="mb-2">
        <small>{title1}</small>
        <br />
        <b>{value1}</b>
        <div className="mt-1" />
        <small>{title2}</small>
        <br />
        <b>{value2}</b>
      </div>
    );

    return (
      <Card className="mt-4 mb-5">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Catatan Approval Surat Jalan</b>
        </Card.Header>
        <Card.Body>
          {dataStakeholder.length > 1 ? (
            <Row>
              {dataStakeholder.map(
                (val, index) =>
                  index !== 0 &&
                  val.status_approval !== "PEN" && (
                    <Col sm>
                      <InfoItem
                        title1={
                          val.status_approval === "VER" ||
                          val.status_approval === "REV"
                            ? `Pemeriksa ${
                                val.approval_level !== "0"
                                  ? val.approval_level
                                  : ""
                              }`
                            : val.status_approval === "APP"
                            ? "Pengesah"
                            : "Di Tolak Oleh"
                        }
                        value1={val.nama_karyawan ?? "-"}
                        title2="Catatan"
                        value2={val.catatan ?? "-"}
                      />
                    </Col>
                  )
              )}
            </Row>
          ) : (
            <div className="d-flex justify-content-center">
              <DataStatus text="Tidak ada catatan" />
            </div>
          )}
        </Card.Body>
      </Card>
    );
  };

  useEffect(() => {
    getInitialData();
    setNavbarTitle("Faktur Penjualan");
  }, []);

  return (
    <>
      <Card style={{ minHeight: "80vh" }}>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Ubah Data Faktur Penjualan</b>
          <BackButton size="sm" onClick={() => history.goBack()} />
        </Card.Header>
        <Card.Body className="pb-5">
          <Alert
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            showCloseButton={true}
            onClose={() =>
              setAlertConfig({
                ...alertConfig,
                show: false,
              })
            }
          />
          {fetchingStatus.loading ? (
            <DataStatus loading text="Memuat . . ." />
          ) : fetchingStatus.success ? (
            <Formik
              initialValues={formInitialValues}
              validationSchema={formValidationSchema}
              onSubmit={formSubmitHandler}
            >
              {(formik) => (
                <form onSubmit={formik.handleSubmit}>
                  <InfoSection dataInfo={dataInfo} />
                  <hr className="mb-1" />
                  <FormSection
                    formik={formik}
                    formRendered={formRendered}
                    fetchingStatus={fetchingStatus}
                    setFormRendered={setFormRendered}
                  />
                  <hr className="mt-1" />
                  <TableSection
                    formik={formik}
                    dataTable={dataTable}
                    setDataTable={setDataTable}
                    data={dataInfo}
                  />
                  <hr />
                  <div className="d-flex justify-content-end">
                    <ActionButton
                      type="submit"
                      variant="success"
                      text="Ubah Faktur Penjualan"
                      loading={formik.isSubmitting}
                    />
                  </div>
                </form>
              )}
            </Formik>
          ) : (
            <DataStatus text="Data gagal dimuat" />
          )}
        </Card.Body>
      </Card>
      {!fetchingStatus.loading && fetchingStatus.success && (
        <CatatanApprovalCard />
      )}
    </>
  );
};

export default UbahFakturPenjualan;
