import Services from '../../../services'

class FakturPembatalanPenjualanApi {
    getPage(page, dataLength) {
        return Services.get(`/faktur_penjualan_pembatalan/page/?page=${page}&per_page=${dataLength}`);
    }
    getSingle(id_faktur_penjualan) {
        return Services.get(`/faktur_penjualan_pembatalan/single?id_faktur_penjualan=${id_faktur_penjualan}`)
    }
    getNomorFakturPembatalan(tanggal) {
        return Services.get(`/faktur_penjualan_pembatalan/no_baru?tanggal=${tanggal}`)
    }
    createFakturPembatalan(data) {
        return Services.post("/faktur_penjualan_pembatalan", data);
    }
    deleteFaktur(id_faktur_penjualan) {
        // TODO : DELETE FAKTUR PEMBATALAN
        return Services.post('/faktur_penjualan_pembatalan/delete', id_faktur_penjualan);
    }
    search(key) {
        return Services.get(`faktur_penjualan_pembatalan/page?q=${key}`);
    }
}

export default new FakturPembatalanPenjualanApi();