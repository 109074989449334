import Logo from "../assets/image/LogoTJS.jpeg";
// import Logo from "../assets/image/LogoCORE.png";

import {
  IoServerOutline,
  IoCashOutline,
  IoSpeedometerOutline,
  IoPersonCircleOutline,
  IoDocumentTextOutline,
} from "react-icons/io5";

// IMPORT PAGE
import {
  // HARGA SATUAN JUAL
  HargaSatuanJual,
  // SALES ORDER
  TransaksiSalesOrder,
  CreateSalesOrder,
  UpdateSalesOrder,
  DetailSalesOrder,
  ListRAB,
  // FAKTUR PENJUALAN
  ListFakturPenjualan,
  ListSalesOrderFakturPenjualan,
  DetailFakturPenjualan,
  TambahFakturPenjualan,
  UbahFakturPenjualan,
  // RETUR FAKTUR PENJUALAN
  ReturPenjualan,
  ListFakturPenjualanRetur,
  CRUReturPenjualan,
  // SALES ORDER SPK
  SalesOrderSPK,
  ListSPK,
  TambahSOSPK,
  UbahSOSPK,
  DetailSOSPK,

  // close sales order

  // LAPORAN PENJUALAN
  LaporanPenjualan,
  // LAPORAN FAKTUR PENJUALAN
  LaporanFakturPenjualan,
} from "../pages/SLS";
import {
  CloseSalesOrderList,
  CloseSalesOrderCreate,
  CloseSalesOrderDetail,
} from "pages/SLS/CloseSalesOrder";
import { SalesOrderList } from "pages/SLS/CloseSalesOrder/SalesOrderList";

export default {
  LOGO: Logo,
  MODUL: "Penjualan",

  /* 
    MENU REQUIREMENTS
      > TEXT  = REQUIRED,
      > LINK  = REQUIRED,
      > EXACT = OPTIONAL (TRUE/FALSE),
      > TYPE  = REQUIRED (MENU/DROPDOWN)
      > HAK   = REQUIRED (HARUS BERBENTUK ARRAY)
      > LOGO  = OPTIONAL 
  */

  MENU: [
    {
      text: "Dashboard",
      link: "/",
      type: "menu",
      exact: true,
      icon: <IoSpeedometerOutline />,
      hak: ["SUPA", "SLS"],
    },
    {
      text: "Master Data",
      type: "dropdown",
      icon: <IoServerOutline />,
      hak: ["SUPA", "SLS"],
      menu: [
        {
          text: "Harga Satuan Jual",
          link: "/sls/master/harga-satuan-jual",
          hak: ["SUPA", "SLS_MAS_HSJ"],
        },
      ],
    },
    {
      text: "Transaksi",
      type: "dropdown",
      icon: <IoCashOutline />,
      hak: ["SUPA", "SLS", "SLS_TRN_SO", "SLS_TRN_SOS", "SLS_TRN_FJ"],
      menu: [
        {
          text: "Sales Order",
          link: "/sls/transaksi/sales-order",
          hak: ["SUPA", "SLS_TRN_SO"],
        },
        {
          text: "Sales Order SPK",
          link: "/transaksi/sales-order-spk",
          hak: ["SUPA", "SLS_TRN_SOS"],
        },
        {
          text: "Faktur Penjualan",
          link: "/sls/transaksi/faktur-penjualan",
          hak: ["SUPA", "SLS_TRN_FJ"],
        },
        {
          text: "Close Sales Order",
          link: "/sls/transaksi/close-sales-order",
          hak: ["SUPA", "SLS_TRN_CSO"],
        },

        // HIDE MENU KARENA TIDAK JADI DIGUNAKAN
        // {
        //   text: "Penerimaan Faktur Penjualan",
        //   link: "/sls/transaksi/penerimaan-faktur-penjualan",
        //   hak: ["SUPA", "SLS_TRN_TB"],
        // },
        // {
        //   text: "Pembatalan Faktur Penjualan",
        //   link: "/sls/transaksi/pembatalan-faktur-penjualan",
        //   hak: ["SUPA", "SLS_TRN_TB"],
        // },

        // HIDE MENU UNTUK PRESENTASI
        // {
        //   text: "Retur Penjualan",
        //   link: "/sls/transaksi/retur-penjualan",
        //   hak: ["SUPA", "SLS_TRN_RTJ"],
        // },
      ],
    },
    {
      text: "Laporan",
      type: "dropdown",
      icon: <IoDocumentTextOutline />,
      hak: ["SUPA", "SLS"],
      menu: [
        {
          text: "Laporan Penjualan",
          link: "/laporan/laporan-penjualan",
          hak: ["SUPA", "SLS_RPT_PNJ"],
        },
        {
          text: "Laporan Faktur Penjualan",
          link: "/laporan/laporan-faktur-penjualan",
          hak: ["SUPA", "SLS_RPT_REGFPNJ"],
        },
      ],
    },
  ],

  ROUTES: [
    // MASTER
    /// HARGA SATUAN JUAL
    {
      exact: true,
      path: "/sls/master/harga-satuan-jual",
      page: HargaSatuanJual,
      hak: ["SUPA", "SLS_MAS_HSJ"],
    },

    // TRANSAKSI
    /// SALES ORDER
    {
      exact: true,
      path: "/sls/transaksi/sales-order",
      page: TransaksiSalesOrder,
      hak: ["SUPA", "SLS_TRN_SO"],
    },
    {
      exact: true,
      path: "/sls/transaksi/sales-order/rab",
      page: ListRAB,
      hak: ["SUPA", "SLS_TRN_SO"],
    },
    {
      exact: true,
      path: "/sls/transaksi/sales-order/tambah",
      page: CreateSalesOrder,
      hak: ["SUPA", "SLS_TRN_SO"],
    },
    {
      exact: true,
      path: "/sls/transaksi/sales-order/update/:id_sales_order",
      page: UpdateSalesOrder,
      hak: ["SUPA", "SLS_TRN_SO"],
    },
    {
      exact: true,
      path: "/sls/transaksi/sales-order/detail/:id_sales_order",
      page: DetailSalesOrder,
      hak: ["SUPA", "SLS_TRN_SO"],
    },

    // FAKTUR PENJUALAN
    {
      exact: true,
      path: "/sls/transaksi/faktur-penjualan",
      page: ListFakturPenjualan,
      hak: ["SUPA", "SLS_TRN_FJ"],
    },
    {
      exact: true,
      path: "/sls/transaksi/faktur-penjualan/sales-order",
      page: ListSalesOrderFakturPenjualan,
      hak: ["SUPA", "SLS_TRN_FJ"],
    },
    {
      exact: true,
      path: "/sls/transaksi/faktur-penjualan/detail/:id",
      page: DetailFakturPenjualan,
      hak: ["SUPA", "SLS_TRN_FJ"],
    },
    {
      exact: true,
      path: "/sls/transaksi/faktur-penjualan/tambah/:id",
      page: TambahFakturPenjualan,
      hak: ["SUPA", "SLS_TRN_FJ"],
    },
    {
      exact: true,
      path: "/sls/transaksi/faktur-penjualan/ubah/:id",
      page: UbahFakturPenjualan,
      hak: ["SUPA", "SLS_TRN_FJ"],
    },

    /// CLOSE SALES ORDER
    {
      exact: true,
      path: "/sls/transaksi/close-sales-order",
      page: CloseSalesOrderList,
      hak: ["SUPA", "SLS_TRN_CSO"],
    },
    {
      exact: true,
      path: "/sls/transaksi/close-sales-order/sales-order",
      page: SalesOrderList,
      hak: ["SUPA", "SLS_TRN_CSO"],
    },
    {
      exact: true,
      path: "/sls/transaksi/close-sales-order/detail/:id",
      page: CloseSalesOrderDetail,
      hak: ["SUPA", "SLS_TRN_CSO"],
    },

    {
      exact: true,
      path: "/sls/transaksi/close-sales-order/sales-order/tambah/:id",
      page: CloseSalesOrderCreate,
      hak: ["SUPA", "SLS_TRN_CSO"],
    },

    /// RETUR PENJUALAN
    {
      // TABEL RETUR PENJUALAN
      exact: true,
      path: "/sls/transaksi/retur-penjualan",
      page: ReturPenjualan,
      hak: ["SUPA", "SLS_TRN_RTJ"],
    },
    {
      // DAFTAR FAKTUR PENJUALAN YANG AKAN DIJADIKAN RETUR PENJUALAN
      exact: true,
      path: "/sls/transaksi/retur-penjualan/list-faktur-penjualan/retur",
      page: ListFakturPenjualanRetur,
      hak: ["SUPA", "SLS_TRN_RTJ"],
    },
    {
      // MENAMPILKAN DETAIL SALES ORDER PADA DAFTAR FAKTUR PENJUALAN
      exact: true,
      path: "/sls/transaksi/retur-penjualan/sales-order/detail/:id_sales_order",
      page: DetailSalesOrder,
      hak: ["SUPA", "SLS_TRN_RTJ"],
    },
    {
      // TAMBAH RETUR PENJUALAN
      exact: true,
      path: "/sls/transaksi/retur-penjualan/:action/faktur-penjualan/:id",
      page: CRUReturPenjualan,
      hak: ["SUPA", "SLS_TRN_RTJ"],
    },
    {
      // UPDATE/DETAIL RETUR PENJUALAN
      exact: true,
      path: "/sls/transaksi/retur-penjualan/:action/:id",
      page: CRUReturPenjualan,
      hak: ["SUPA", "SLS_TRN_RTJ"],
    },

    // SALES ORDER SPK
    {
      exact: true,
      path: "/transaksi/sales-order-spk",
      page: SalesOrderSPK,
      hak: ["SUPA", "SLS_TRN_SOS"],
    },
    {
      exact: true,
      path: "/transaksi/sales-order-spk/list-spk",
      page: ListSPK,
      hak: ["SUPA", "SLS_TRN_SOS"],
    },
    {
      exact: true,
      path: "/transaksi/sales-order-spk/tambah/:id_spk",
      page: TambahSOSPK,
      hak: ["SUPA", "SLS_TRN_SOS"],
    },
    {
      exact: true,
      path: "/transaksi/sales-order-spk/ubah/:id_sales_order_spk",
      page: UbahSOSPK,
      hak: ["SUPA", "SLS_TRN_SOS"],
    },
    {
      exact: true,
      path: "/transaksi/sales-order-spk/detail/:id_sales_order_spk",
      page: DetailSOSPK,
      hak: ["SUPA", "SLS_TRN_SOS"],
    },

    // LAPORAN MODUL PENJUALAN
    {
      exact: true,
      path: "/laporan/laporan-penjualan",
      page: LaporanPenjualan,
      hak: ["SUPA", "SLS_RPT_PNJ"],
    },
    // LAPORAN FAKTUR PENJUALAN
    {
      exact: true,
      path: "/laporan/laporan-faktur-penjualan",
      page: LaporanFakturPenjualan,
      hak: ["SUPA", "SLS_RPT_REGFPNJ"],
    },
  ],
};
