// React
import { useEffect, useState } from 'react'

// Component
import { Row, Col } from 'react-bootstrap'
import { 
	DatePicker, DataStatus, Input, SelectSearch
} from 'components'
import { DateConvert } from 'utilities'

// API
import { 
	TransaksiSalesOrderApi, RegisterSalesOrderApi 
} from 'api'

const InfoPeluangSection = ({ formik, readOnly = false }) => {
	// States
	const [loading, setLoading] = useState(false)
	const [dataSales, setDataSales] = useState([])

	useEffect(() => {
		if (!formik.values.no_sales_order) {
			getNoBaru(DateConvert(new Date()).default)
		}

		setLoading(true)
		RegisterSalesOrderApi.getSales()
			.then(res => setDataSales(res?.data?.data ?? []))
			.finally(() => setLoading(false))

		return () => {
			setLoading(false)
			setDataSales([])
		}
	}, [])

	const getNoBaru = (date) => {
		TransaksiSalesOrderApi.getNomorSalesOrder(date)
			.then(res => formik.setFieldValue('no_sales_order', res.data.data))
	}
	
	if (loading) {
		return <DataStatus loading={true} text="Memuat..." />
	} else {
		return (
			<>
				<Row>
					<Col lg="2">
						<DatePicker
							disabled={readOnly}
							label="Tgl. Sales Order"
							placeholderText="Pilih Tgl. Sales Order"
							selected={formik.values.tgl_sales_order ? new Date(formik.values.tgl_sales_order) : ""}
							onChange={val => {
								const date = DateConvert(new Date(val)).default
								formik.setFieldValue("tgl_sales_order", date)
	
								getNoBaru(date)
							}}
							error={formik.errors.tgl_sales_order && formik.touched.tgl_sales_order && true}
							errorText={formik.errors.tgl_sales_order && formik.touched.tgl_sales_order && formik.errors.tgl_sales_order}
						/>
					</Col>
	
					<Col lg="3">
						<Input
							label="No. Sales Order"
							value={formik.values.no_sales_order}
							readOnly={true}
						/>
					</Col>
	
					<Col lg="2">
						<DatePicker
							disabled={readOnly}
							label="Tgl. Batas Akhir"
							placeholderText="Pilih Tgl. Batas Akhir"
							selected={formik.values.batas_waktu ? new Date(formik.values.batas_waktu) : ""}
							onChange={val => formik.setFieldValue("batas_waktu", DateConvert(new Date(val)).default)}
							error={formik.errors.batas_waktu && formik.touched.batas_waktu && true}
							errorText={formik.errors.batas_waktu && formik.touched.batas_waktu && formik.errors.batas_waktu}
						/>
					</Col>

					<Col lg>
						<SelectSearch
							isDisabled={readOnly}
							label="Sales"
							placeholder='Pilih Sales'
							defaultValue={formik.values.id_karyawan ? dataSales.map(item => {
								if (item.id_karyawan === formik.values.id_karyawan) {
									return {
										label: item.nama_karyawan,
										value: item.id_karyawan
									}
								}
							}) : ""}
							onChange={(val) => formik.setFieldValue("id_karyawan", val.value)}
							option={dataSales.map(val => {
								return {
									label: val.nama_karyawan,
									value: val.id_karyawan
								}
							})}
							error={formik.errors.id_karyawan && formik.touched.id_karyawan && true}
							errorText={Boolean(formik.errors.id_karyawan && formik.touched.id_karyawan) && formik.errors.id_karyawan}
						/>
					</Col>
				</Row>
			</>
		)
	}
}

export default InfoPeluangSection