import React from 'react'
import {
    Button
} from 'react-bootstrap'

const StatusButton = ({ onClick, variant = "primary", size = "sm", text = "Progress" }) => {
  return (
    <Button
      variant={variant}
      onClick={onClick}
      size={size}
      className="m-1"
    >
      <span className="text-uppercase" style={{ fontSize: "0.65rem" }}>
        {text}
      </span>
    </Button>
  )
}

export default StatusButton
