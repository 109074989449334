import React from 'react'
import {
    OverlayTrigger,
    Tooltip,
    Button
} from 'react-bootstrap'
import {
    IoAddOutline
} from 'react-icons/io5'

const CreateButton = ({ onClick, variant = "primary", tooltipText, tooltipPlacement, size = "md", className }) => {
    return (
        <OverlayTrigger
            placement={tooltipPlacement}
            overlay={
                <Tooltip>
                    {tooltipText ? tooltipText : 'Tambah Customer Baru'}
                </Tooltip>
            }>
            <Button
                size={size}
                variant={variant}
                onClick={onClick}
                className={`m-1 ${className}`}>
                <IoAddOutline size={18} />
            </Button>
        </OverlayTrigger>
    )
}

export default CreateButton
