import Services from '../../../services'

class ReturPenjualanApi {
    getPage(page, dataLength) {
        return Services.get(`/retur_penjualan/page/?page=${page}&per_page=${dataLength}`);
    }
    getDetailRetur(id_retur_penjualan) {
        return Services.get(`/retur_penjualan/single?id_retur_penjualan=${id_retur_penjualan}`)
    }
    getDetailFaktur(id_faktur_penjualan) {
        return Services.get(`/retur_penjualan/faktur_detail/single?id_faktur_penjualan=${id_faktur_penjualan}`)
    }
    getNomorReturPenjualan(tanggal) {
        return Services.get(`retur_penjualan/no_baru?tgl_retur_penjualan=${tanggal}`)
    }
    createReturPenjualan(data) {
        return Services.post("/retur_penjualan", data);
    }
    updateReturPenjualan(data) {
        return Services.put("/retur_penjualan", data);
    }
    deleteReturPenjualan(id_retur_penjualan) {
        return Services.post('/retur_penjualan/delete', { id_retur_penjualan });
    }
    search(key) {
        return Services.get(`retur_penjualan/page?q=${key}`);
    }
    getListFakturPenjualanDO(page, dataLength) {
        return Services.get(`/faktur_penjualan/with_do?page=${page}&per_page=${dataLength}`);
    }
    searchFakturPenjualanDO(key) {
        return Services.get(`/faktur_penjualan/with_do?q=${key}`);
    }
}

export default new ReturPenjualanApi();