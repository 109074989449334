import React, { useState, useEffect } from 'react'
import { useHistory, Link, useLocation } from 'react-router-dom'
import {
    CRUDLayout,
    InputSearch,
    DataStatus,
    Alert,
    Pagination,
    THead,
    TBody,
    ThFixed,
    TdFixed,
    Tr,
    Th,
    Td,
    StatusButton,
    BackButton,
    ActionButton,
    CreateCustomerButton
} from '../../../components'
import {
    OverlayTrigger,
    Tooltip
} from 'react-bootstrap'
import Axios from 'axios'
import {
    TransaksiSalesOrderApi,
    RegisterSalesOrderApi,
    FakturPenjualanApi,
    ReturPenjualanApi
} from '../../../api'
import { DateConvert, PageNumber } from '../../../utilities'

// PRINT LAYOUT COMPONENT
const ListFakturPenjualanRetur = ({ setNavbarTitle }) => {
    // USE HISTORY 
    const history = useHistory();

    // USE LOCATION
    // const location = useLocation();
    // let { action } = location.state

    // STATE LOADING 
    const [isLoading, setIsLoading] = useState(true);

    // STATE SEARCHING
    const [isSearching, setIsSearching] = useState(false);
    const [searchKey, setSearchKey] = useState('');

    // STATE DATA SATUAN
    const [data, setData] = useState([]);
    const [dataCount, setDataCount] = useState(0)
    const [page, setPage] = useState(1);
    const [dataLength, setDataLength] = useState(10);
    const [totalPage, setTotalPage] = useState(1);

    // ALERT STATE
    const [showAlert, setShowAlert] = useState(false);
    // KONFIGURASI ALERT
    const [alertConfig, setAlertConfig] = useState({
        variant: "primary",
        text: "",
    });

    // FUNCTION GET DATA DARI SERVER
    const getData = () => {
        setIsLoading(true);
        setIsSearching(false);
        setSearchKey('');

        Axios.all([
            ReturPenjualanApi.getListFakturPenjualanDO(page, dataLength),
        ]).then(
            Axios.spread((res) => {
                sortedByDateList(res.data.data);
                setTotalPage(res.data.total_page);
                setDataCount(res.data.data_count)
            })
        )
            .catch((err) => alert(err))
            .finally(() => setIsLoading(false))
    }

    // FUNCTION KONVERSI TANGGAL INPUT
    const getConvertedDate = (inputDate) => {
        if (inputDate != null) {
            const getDate = inputDate.split(" ");
            const date = new Date(getDate[0]);
            return `${DateConvert(date).defaultDay}/${DateConvert(date).defaultMonth}/${DateConvert(date).defaultYear}`
            // return DateConvert(date).default
        }
        return "-"
    }

    // FUNCTION SORTIR LIST ITEM BAHAN BERDASARKAN TANGGAL TERBARU
    const sortedByDateList = (listItemBahan) => {
        const sorted = listItemBahan.sort(function (a, b) {
            return new Date(b.tgl_input) - new Date(a.tgl_input);
        });
        setData(sorted);
    }

    // FUNCTION CARI DATA DARI SERVER 
    const searchData = (e) => {
        e.preventDefault();
        const key = e.target.value  // SearchKey Value as key

        setSearchKey(key)
        setIsLoading(true);

        // SEARCH DATA WITH KEY FROM DATABASE
        ReturPenjualanApi.searchFakturPenjualanDO(key)
            .then((res) => {
                // setData(res.data.data);
                sortedByDateList(res.data.data);
                setTotalPage(res.data.total_page);
                setDataCount(res.data.data_count)
            })
            .catch((err) => alert(err))
            .finally(() => {
                setIsSearching(true);
                setIsLoading(false);

                key !== '' && setAlertConfig({
                    variant: "primary",
                    text: `Hasil Pencarian : ${key}`,
                });
                key !== '' ? setShowAlert(true) : setShowAlert(false);
            });
    }

    // ON COMPONENT MOUNT
    useEffect(() => {
        setNavbarTitle('Daftar Faktur Penjualan'); // SET JUDUL NAVBAR
        getData();  // GET DATA DARI SERVER

        return () => {
            setIsLoading(false);
            setIsSearching(false);
        }
    }, [page, dataLength])

    // MAIN TABLE COMPONENT
    const Table = () => {
        return (
            <>
                <CRUDLayout.Table>
                    <THead>
                        <Tr className="text-center">
                            <ThFixed>No</ThFixed>
                            <ThFixed>Aksi</ThFixed>
                            <ThFixed>Tgl. Faktur Penjualan</ThFixed>
                            <ThFixed>No. Faktur Penjualan</ThFixed>
                            <ThFixed>No. Faktur SIA</ThFixed>
                            <ThFixed >No. Sales Order</ThFixed>
                            <Th>Customer</Th>
                            <Th>Sales</Th>
                        </Tr>
                    </THead>
                    <TBody>
                        {data.map((val, index) => {
                            return (
                                <Tr key={val.id_faktur_penjualan}>
                                    <TdFixed >{PageNumber(page, dataLength, index)}</TdFixed>
                                    <Td >
                                        <div className="d-flex flex-column justify-content-center align-items-stretch" style={{ minWidth: "7.5rem" }}>
                                            <StatusButton
                                                variant="primary"
                                                text="Retur Penjualan"
                                                onClick={() => {
                                                    console.log(val.id_faktur_penjualan)
                                                    history.push(`/sls/transaksi/retur-penjualan/tambah/faktur-penjualan/${val.id_faktur_penjualan}`)
                                                }} />
                                        </div>
                                    </Td>
                                    <TdFixed>{getConvertedDate(val.tgl_faktur)}</TdFixed >
                                    <TdFixed>{val.no_faktur}</TdFixed>
                                    <TdFixed>{val?.no_sia ?? '-'}</TdFixed> { /*//TODO: UBAH MENJADI NO SIA */}
                                    <TdFixed>
                                        <Link to={`/sls/transaksi/retur-penjualan/sales-order/detail/${val.id_sales_order}`} style={{ cursor: "pointer" }}>
                                            {val.no_sales_order}
                                        </Link>
                                    </TdFixed>
                                    <TdFixed>{val.nama_customer}</TdFixed>
                                    <TdFixed>{val.nama_sales}</TdFixed>
                                </Tr>
                            );
                        })}
                    </TBody>
                </CRUDLayout.Table>
                 <Pagination
                    dataLength={dataLength}
                    dataNumber={page * dataLength - dataLength + 1}
                    dataPage={page * dataLength}
                    dataCount={dataCount}
                    onDataLengthChange={(e) => {
                        setPage(1)
                        setDataLength(e.target.value)
                    }}
                    currentPage={page}
                    totalPage={totalPage}
                    onPaginationChange={({ selected }) => setPage(selected + 1)}

                />
            </>
        )
    }

    return (
        <CRUDLayout>
            <CRUDLayout.Head>
                <CRUDLayout.HeadSearchSection>
                    <div className="d-flex mb-3">
                        <InputSearch placeholder="Cari Faktur Penjualan"
                            value={searchKey}
                            onChange={searchData} 
                        />
                    </div>
                </CRUDLayout.HeadSearchSection>

                {/* Button Section */}
                <CRUDLayout.HeadButtonSection>
                    {/* Button Tambah */}
                    <BackButton onClick={() => {
                        history.goBack()
                    }} />
                </CRUDLayout.HeadButtonSection>
            </CRUDLayout.Head>

            {/* Alert */}
            <Alert show={showAlert}
                showCloseButton={true}
                variant={alertConfig.variant}
                text={alertConfig.text}
                onClose={() => setShowAlert(false)} />

            {
                isLoading ? (<DataStatus loading={isLoading} text="Memuat Data" />) :
                    (!(Array.isArray(data))) ? (<DataStatus text="Tidak dapat memuat data" />)
                        : data.length > 0
                            ? (<Table />)
                            : (<div className="d-flex justify-content-center align-items-center">
                                <DataStatus text="Data Faktur Tidak Ditemukan" />
                                <CreateCustomerButton className="ml-3" size="sm" tooltipText="Tambah faktur baru" onClick={() => history.push('/sls/transaksi/faktur-penjualan/list-sales-order')} />
                            </div>)
            }
        </CRUDLayout>
    )
}

export default ListFakturPenjualanRetur