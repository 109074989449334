import {
  useState,
  useEffect
} from 'react'
import {
  Row,
  Col
} from 'react-bootstrap'
import {
  DatePicker,
  Input
} from 'components'
import {
  DateConvert,
} from 'utilities'
import {
  FakturPenjualanApi
} from 'api'

const FormSection = ({formik, fetchingStatus, formRendered, setFormRendered}) => {
  const [isNomorLoading, setIsNomorLoading] = useState(false)
  const {values, setFieldValue} = formik

  useEffect(() => {
    return () => {
      setFormRendered(formRendered + 1)
      setIsNomorLoading(false)
    }
  }, [])
  
  return (
    <div>
      <Row>
        <Col md>
          <DatePicker 
            label="Tgl. Faktur Penjualan"
            placeholderText="Pilih tanggal"
            selected={values.tanggal ? new Date(values.tanggal) : ""}
            onChange={date => {
              const newDate = DateConvert(date).default
              setFieldValue("tanggal", newDate)
            }}
          />
        </Col>
        <Col md>
          <Input 
            readOnly
            label="No. Faktur penjualan"
            placeholder={isNomorLoading ? "Memuat nomor . . ." : "Pilih tanggal untuk menentukan nomor"}
            value={isNomorLoading ? "Memuat nomor . . ." : values.nomor}
          />
        </Col>
      </Row>
    </div>
  )
}

export default FormSection