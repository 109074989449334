import Services from "services";

class LaporanFakturPenjualanApi {
  getPage(params) {
    return Services.get("/laporan_faktur_penjualan/page", { params });
  }

  getDropdown(params) {
    return Services.get("/laporan_faktur_penjualan/dropdown", { params });
  }

  getDropdownUnitProduksi() {
    return Services.get("/dropdown/unit_produksi");
  }

  getExport(params) {
    return Services.get("/faktur_penjualan_report", { params });
  }
}

export default new LaporanFakturPenjualanApi();
