// React
import { useEffect, useState } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";

// Section
import { InfoPeluangSection, InfoRABSection, FormSection, ListBarangJadiSection } from "./Section";

// Component
import { DataStatus, BackButton, Alert, ActionButton } from "components";
import { Card } from "react-bootstrap";
import { DateConvert } from "utilities";

// Form
import { Formik } from "formik";
import * as yup from "yup";
import { TransaksiSalesOrderApi } from "api";

// Function
import { checkGudang } from "./Function";

const UpdateSalesOrder = ({ setNavbarTitle }) => {
  // Variables
  const { id_sales_order } = useParams();
  const title = "Sales Order";
  const history = useHistory();
  const location = useLocation();

  // States
  const [page, setPage] = useState({
    loading: true,
    status: false,
  });
  const [data, setData] = useState({});
  const [dataDetail, setDataDetail] = useState([]);
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "",
    text: "",
  });

  useEffect(() => {
    setNavbarTitle(title);

    TransaksiSalesOrderApi.single({
      id_sales_order,
      id_unit_produksi: sessionStorage.getItem("id_unit_produksi"),
    })
      .then((res) => {
        const value = res.data.data;
        setData(value);

        const detail = value?.detail ?? [];
        let detailBaru = [];
        if (detail.length > 0) {
          detail.map((val) =>
            detailBaru.push({
              ...val,
              gudang: val.gudang ?? [],
              kode_item: val.kode_barang ?? "-",
              nama_item: val.nama_barang ?? "-",
              kode_satuan_jual: val.kode_satuan ?? "-",
              qty_sales_order: val.qty_sales_order
                ? parseFloat(val.qty_sales_order).toPrecision()
                : 0,
              qty_item: val.qty_sales_order ? parseFloat(val.qty_sales_order).toPrecision() : 0,
              unit_cost: val.unit_cost ? parseFloat(val.unit_cost) : 0,
            })
          );
        }
        setDataDetail(detailBaru);

        setPage({
          loading: false,
          status: true,
        });
      })
      .catch(() =>
        setPage({
          loading: false,
          status: false,
        })
      );

    return () =>
      setPage({
        loading: true,
        status: true,
      });

    // eslint-disable-next-line
  }, []);

  const initialValues = {
    id_sales_order,
    tgl_sales_order: data?.tgl_sales_order
      ? DateConvert(new Date(data.tgl_sales_order)).default
      : "",
    no_sales_order: data?.no_sales_order ?? "",
    id_rab: data?.id_rab ?? "",
    batas_waktu: data?.batas_waktu ? DateConvert(new Date(data.batas_waktu)).default : "",
    id_karyawan: data?.id_sales ?? null,
    diskon: data?.diskon ? parseFloat(data.diskon) : 0,
    ppn: data?.ppn ? parseFloat(data.ppn) : 0,
    catatan: data?.catatan ?? "",
    alamat_pengiriman: data?.alamat_pengiriman ?? "",
    id_customer: data?.id_customer ?? "",
  };

  const validationSchema = yup.object().shape({
    tgl_sales_order: yup.date().required("Tgl. Sales Order wajib diisi"),
    no_sales_order: yup.string().required("No. Sales Order wajib diisi").nullable(),
    batas_waktu: yup.date().required("Tgl. Batas Waktu wajib diisi"),
    id_karyawan: yup.string().required("Sales wajib diisi").nullable(),
    diskon: yup.number().typeError("Format angka salah").required("Sales wajib diisi").nullable(),
    ppn: yup.number().typeError("Format angka salah").required("PPn wajib diisi").nullable(),
  });

  const handleSubmit = (values, { setSubmitting }) => {
    if (!checkGudang(dataDetail)) {
      setAlertConfig({
        show: true,
        variant: "danger",
        text: "List Item Barang Jadi belum memiliki Gudang",
      });

      return setSubmitting(false);
    }

    TransaksiSalesOrderApi.updateSalesOrder({
      ...values,
      detail: dataDetail,
    })
      .then(() =>
        history.push("/sls/transaksi/sales-order", {
          registerAlertConfig: {
            variant: "success",
            text: "Ubah Sales Order Berhasil",
          },
          ...location.state,
        })
      )
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Ubah Sales Order Berhasil",
        })
      )
      .finally(() => setSubmitting(false));
  };

  if (page.loading) {
    return <DataStatus loading={true} text="Memuat Data..." />;
  } else if (!page.status) {
    return <DataStatus text="Data Gagal Dimuat" />;
  } else {
    return (
      <>
        <Alert
          show={alertConfig.show}
          showCloseButton={true}
          variant={alertConfig.variant}
          text={alertConfig.text}
          onClose={() =>
            setAlertConfig({
              ...alertConfig,
              show: false,
            })
          }
        />

        <div className="d-flex justify-content-between align-items-end mb-2">
          <h6 className="font-weight-bold">Ubah Data {title}</h6>
          <BackButton
            size="sm"
            onClick={() => history.push("/sls/transaksi/sales-order", location.state)}
          />
        </div>

        <Card>
          <Card.Body>
            <InfoPeluangSection data={data} />
            <InfoRABSection data={data} />
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {(formik) => (
                <form onSubmit={formik.handleSubmit}>
                  <FormSection formik={formik} />
                  <ListBarangJadiSection
                    dataDetail={dataDetail}
                    setDataDetail={setDataDetail}
                    formik={formik}
                    data={data}
                  />

                  <div className="text-right">
                    <ActionButton
                      type="submit"
                      text="Simpan"
                      variant="primary"
                      loading={formik.isSubmitting}
                    />
                  </div>
                </form>
              )}
            </Formik>
          </Card.Body>
        </Card>
      </>
    );
  }
};

export default UpdateSalesOrder;
