import { ButtonBack, ModalConfirm } from "@bhawanadevteam/react-core";
import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { InfoSection, ModalConfirmSection, TableSection } from "./Section";
import { CloseSalesOrderApi } from "api";
import { ActionButton, Alert, DataStatus } from "components";
import { Formik } from "formik";
import { formInitialValues, formValidationSchema } from "./Utils";
import { useLocation, useParams } from "react-router-dom/cjs/react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const CloseSalesOrderDetail = ({ setNavbarTitle }) => {
  const { id } = useParams();
  const { state } = useLocation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "success",
    text: "",
  });

  const getInitialValues = () => {
    setIsLoading(true);
    CloseSalesOrderApi.getSingleSO({ id_sales_order: id, is_spk: state.is_spk })
      .then(({ data }) => setData(data?.data))
      .catch((err) =>
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data Gagal Dimuat",
        })
      )
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    setNavbarTitle("Close Sales Order");
    getInitialValues();

    return () => {};
  }, [setNavbarTitle]);

  const showingModalConfirm = () => {
    setShowModalConfirm(true);
  };

  return isLoading ? (
    <DataStatus loading text="Memuat Data..." />
  ) : (
    <>
      <div className="d-flex justify-content-between mb-3">
        <b>Detail Data Sales Order</b>
        <ButtonBack onClick={() => history.goBack()} />
      </div>

      <Card>
        <Formik
          initialValues={formInitialValues(data)}
          validationSchema={formValidationSchema}
          onSubmit={showingModalConfirm}
        >
          {({ handleSubmit }) => (
            <Card.Body>
              <Alert
                show={alertConfig.show}
                variant={alertConfig.variant}
                text={alertConfig.text}
              />
              <InfoSection isSpk={state?.is_spk} action="read" />
              <TableSection />
              <Alert
                className="p-2"
                variant="danger"
                show
                text="Data sales order ini akan berubah statusnya dari ON PROGRESS menjadi CLOSED dan tidak dapat dikembalikan. Data produksi dengan status CLOSED tidak dapat digunakan sebagai acuan untuk Delivery Order, dan Produksi"
              />
              <div className="d-flex justify-content-end">
                <ActionButton
                  text="CLOSE SALES ORDER"
                  variant="danger"
                  size="sm"
                  type="submit"
                  onClick={handleSubmit}
                />
              </div>
              {showModalConfirm && (
                <ModalConfirmSection
                  show={showModalConfirm}
                  setShow={setShowModalConfirm}
                  setAlertConfig={setAlertConfig}
                />
              )}
            </Card.Body>
          )}
        </Formik>
      </Card>
    </>
  );
};

export default CloseSalesOrderDetail;
