import { TBody, THead, Table, Td, Th, ThFixed, Tr } from "components";
import { useFormikContext } from "formik";
import React from "react";
import { DecimalConvert } from "utilities";

export const TableSection = () => {
  const { values } = useFormikContext();
  return (
    <div className="mt-3">
      <b>List Barang Jadi </b>
      <Table>
        <THead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <ThFixed className="text-nowrap">Kode Barang Jadi</ThFixed>
            <Th>Barang Jadi</Th>
            <Th>Satuan</Th>
            <Th>Qty. SO</Th>
            <Th>Qty. Telah Dikirim</Th>
            <Th>Qty. Telah Di Produksi</Th>
          </Tr>
        </THead>
        <TBody>
          {values?.detail &&
            values?.detail.length > 0 &&
            values?.detail?.map((val, ind) => (
              <Tr>
                <Td>{ind + 1}</Td>
                <Td>{val.kode_barang ?? "-"}</Td>
                <Td>{val.nama_barang ?? "-"}</Td>
                <Td>{val?.nama_satuan ?? "-"}</Td>
                <Td textRight>
                  {
                    DecimalConvert(parseFloat(val?.qty_sales_order ?? 0))
                      .getWithComa
                  }
                </Td>
                <Td textRight>
                  {
                    DecimalConvert(parseFloat(val?.qty_terkirim ?? 0))
                      .getWithComa
                  }
                </Td>
                <Td textRight>
                  {
                    DecimalConvert(parseFloat(val?.qty_diproduksi ?? 0))
                      .getWithComa
                  }
                </Td>
              </Tr>
            ))}
        </TBody>
      </Table>
    </div>
  );
};
