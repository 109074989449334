// React
import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

// Component
import {
  CRUDLayout,
  InputSearch,
  BackButton,
  DataStatus,
  TdFixed,
  ActionButton,
  Alert,
  Pagination,
  THead,
  TBody,
  ThFixed,
  Tr,
  Th,
  Td,
  FilterButton,
} from "../../../components";
import { DateConvert, DecimalConvert, PageNumber } from "../../../utilities";
import { ButtonGroup } from "react-bootstrap";

// API
import { TransaksiSalesOrderApi } from "../../../api";

// Icon
import { IoAddOutline } from "react-icons/io5";

// View Components
import { ModalFilterRAB } from "./Section";

// PRINT LAYOUT COMPONENT
const ListRAB = ({ setNavbarTitle }) => {
  const title = "LIST RAB YANG SIAP DIBUATKAN SALES ORDER";
  // USE HISTORY
  const history = useHistory();

  const location = useLocation();
  let registerAlertConfig = location?.state?.registerAlertConfig;
  const so = location?.state?.so ?? {};
  const filter = location?.state?.rab ?? {};

  // STATE LOADING
  const [isLoading, setIsLoading] = useState(true);

  // STATE DATA SATUAN
  const [data, setData] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [totalPage, setTotalPage] = useState(1);
  const [modalFilter, setModalFilter] = useState(false);
  const [dataFilter, setDataFilter] = useState({
    page: filter.page ?? 1,
    per_page: filter.per_page ?? 10,
    q: filter.q ?? "",
    active: filter.active,
    tgl_rab_mulai: filter.tgl_rab_mulai,
    tgl_rab_selesai: filter.tgl_rab_selesai,
    tgl_rae_mulai: filter.tgl_rae_mulai,
    tgl_rae_selesai: filter.tgl_rae_selesai,
    customer: filter.customer,
    baseline: filter.baseline,
  });

  // STATE MENYIMPAN DATA PRINT FAKTUR
  const [showAlert, setShowAlert] = useState(false);
  // KONFIGURASI ALERT
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  });

  // FUNCTION GET DATA DARI SERVER
  const getData = () => {
    setIsLoading(true);
    const filterData = { ...dataFilter };
    delete filterData.active;

    TransaksiSalesOrderApi.rab({
      ...filterData,
      // id_unit_produksi: sessionStorage.getItem('id_unit_produksi') ?? 0
    })
      .then((res) => {
        setData(res.data.data);
        setTotalPage(res.data.total_page);
        setDataCount(res.data.data_count);
      })
      .catch((err) => alert(err))
      .finally(() => {
        setIsLoading(false);

        filterData.q !== "" &&
          setAlertConfig({
            variant: "primary",
            text: `Hasil Pencarian : ${filterData.q}`,
          });
        filterData.q !== "" ? setShowAlert(true) : <></>;
      });
  };

  // FUNCTION KONVERSI TANGGAL INPUT
  const getConvertedDate = (inputDate) => {
    if (inputDate != null) {
      const getDate = inputDate.split(" ");
      const date = new Date(getDate[0]);
      return `${DateConvert(date).defaultDay}/${
        DateConvert(date).defaultMonth
      }/${DateConvert(date).defaultYear}`;
      // return DateConvert(date).detail
    }
    return "-";
  };

  // ON COMPONENT MOUNT
  useEffect(() => {
    setNavbarTitle(title); // SET JUDUL NAVBAR
    getData(); // GET DATA DARI SERVER

    // SHOW ALERT, IF REGISTER ALERT IS EXIST
    if (registerAlertConfig !== undefined) {
      setAlertConfig(registerAlertConfig);
      setShowAlert(true);
    }

    return () => {
      setIsLoading(false);
    };

    // eslint-disable-next-line
  }, [dataFilter]);

  // MAIN TABLE COMPONENT
  const Table = () => {
    return (
      <>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              <ThFixed>Informasi RAB</ThFixed>
              <ThFixed>Informasi Unit Cost</ThFixed>
              <Th>Customer</Th>
              <Th>Barang Jadi</Th>
              <Th>Unit Produksi</Th>
              <ThFixed>Baseline</ThFixed>
              <ThFixed>Aksi</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => {
              return (
                <Tr key={val.id_sales_order}>
                  <TdFixed textCenter>
                    {PageNumber(dataFilter.page, dataFilter.per_page, index)}
                  </TdFixed>
                  <Td>
                    <div>
                      {val.tgl_rab
                        ? DateConvert(new Date(val.tgl_rab)).defaultDMY
                        : "-"}
                    </div>
                    <div>{val.no_rab ?? "-"}</div>
                  </Td>
                  <Td>
                    <div>
                      {val.tgl_rae
                        ? DateConvert(new Date(val.tgl_rae)).defaultDMY
                        : "-"}
                    </div>
                    <div>{val.no_rae ?? "-"}</div>
                  </Td>
                  <Td>{val.nama_customer ?? "-"}</Td>
                  <Td>
                    <div>
                      {val?.detail?.length > 0 ? (
                        <ul className="pl-3">
                          {val?.detail.map((e, i) => (
                            <li key={i}>
                              {e.nama_item
                                ? `${e.nama_item} x ${
                                    e.qty_rab
                                      ? DecimalConvert(e.qty_rab).getWithComa
                                      : 0
                                  } ${e.kode_satuan_pakai}`
                                : "-"}
                            </li>
                          ))}
                        </ul>
                      ) : (
                        "-"
                      )}
                    </div>
                  </Td>
                  <Td>{val.nama_unit_produksi ?? "-"}</Td>
                  <TdFixed textCenter>{val.baseline ?? "-"}</TdFixed>
                  <TdFixed>
                    <div className="d-flex flex-row justify-content-start">
                      <ButtonGroup size="sm">
                        {/* <ReadButton
                          onClick={() => history.push(`/sls/transaksi/sales-order/rab`, {
                            data: val
                          })}
                        /> */}
                        <ActionButton
                          variant="success"
                          text={<IoAddOutline />}
                          onClick={() =>
                            history.push("/sls/transaksi/sales-order/tambah", {
                              data: val,
                              rab: dataFilter,
                              so,
                            })
                          }
                        />
                      </ButtonGroup>
                    </div>
                  </TdFixed>
                </Tr>
              );
            })}
          </TBody>
        </CRUDLayout.Table>
        <Pagination
          dataLength={dataFilter.per_page}
          dataNumber={
            dataFilter.page * dataFilter.per_page - dataFilter.per_page + 1
          }
          dataPage={dataFilter.page * dataFilter.per_page}
          dataCount={dataCount}
          onDataLengthChange={(e) =>
            setDataFilter({
              ...dataFilter,
              page: 1,
              per_page: e.target.value,
            })
          }
          currentPage={dataFilter.page}
          totalPage={totalPage}
          onPaginationChange={({ selected }) =>
            setDataFilter({
              ...dataFilter,
              page: selected + 1,
            })
          }
        />
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              placeholder="Cari Sales Order"
              value={dataFilter.q}
              onChange={(e) =>
                setDataFilter({
                  ...dataFilter,
                  q: e.target.value,
                })
              }
            />

            <FilterButton
              active={dataFilter?.active}
              onClick={() => setModalFilter(true)}
            />
          </div>
        </CRUDLayout.HeadSearchSection>

        {/* Button Section */}
        <CRUDLayout.HeadButtonSection>
          {/* Button Export */}
          {/* <ExportButton /> */}
          {/* Button Tambah */}
          <BackButton
            onClick={() =>
              history.push("/sls/transaksi/sales-order", {
                rab: dataFilter,
                so,
              })
            }
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => {
          history.replace("/sls/transaksi/sales-order", {
            registerAlertConfig: undefined,
          });
          setShowAlert(false);
        }}
      />

      {isLoading ? (
        <DataStatus loading={isLoading} text="Memuat Data" />
      ) : !Array.isArray(data) ? (
        <DataStatus text="Tidak dapat memuat data" />
      ) : data.length > 0 ? (
        <Table />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}

      {modalFilter && (
        <ModalFilterRAB
          show={modalFilter}
          setShow={setModalFilter}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}
    </CRUDLayout>
  );
};

export default ListRAB;
