// React
import { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";

// Section
import {
  InfoPeluangSection,
  InfoRABSection,
  FormSection,
  ListBarangJadiSection,
} from "./Section";

// Component
import { DataStatus, BackButton, ActionButton, Alert } from "components";
import { Card } from "react-bootstrap";
import { DateConvert } from "utilities";

// Form
import { Formik } from "formik";
import * as yup from "yup";
import { TransaksiSalesOrderApi } from "api";

// Function
import { checkGudang } from "./Function";

const CreateSalesOrder = ({ setNavbarTitle }) => {
  // Variables
  const title = "Sales Order";
  const location = useLocation();
  const history = useHistory();
  const data = location?.state?.data ?? {};
  console.log(location);

  // States
  const [dataDetail, setDataDetail] = useState([]);
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "",
    text: "",
  });
  const [krditLimitAlertConfig, setKrditLimitAlertConfig] = useState({
    show: false,
    variant: "",
    text: "",
  });

  useEffect(() => {
    setNavbarTitle(title);

    const detail = data?.detail ?? [];
    let detailBaru = [];
    if (detail.length > 0) {
      // eslint-disable-next-line
      detail.map((val) => {
        let id_gudang = null;
        id_gudang =
          val.gudang.length > 0 &&
          val.gudang.find((_val, index) => index === 0).id_gudang;

        detailBaru.push({
          gudang: val.gudang,
          kode_item: val.kode_item ?? "-",
          nama_item: val.nama_item ?? "-",
          kode_satuan_jual: val.kode_satuan_jual ?? "-",
          id_barang: val.id_item_buaso,
          qty_item: val.qty_rab ? parseFloat(val.qty_rab).toPrecision() : 0,
          qty_sales_order: val.qty_rab
            ? parseFloat(val.qty_rab).toPrecision()
            : 0,
          // unit_cost: val.unit_cost ? parseInt(val.unit_cost) : 0,
          unit_cost: val.rounded ? parseInt(val.rounded) : 0,
          id_gudang,
        });
      });
    }
    setDataDetail(detailBaru);

    // eslint-disable-next-line
  }, []);

  const initialValues = {
    tgl_sales_order: DateConvert(new Date()).default,
    no_sales_order: null,
    id_rab: data?.id_rab,
    batas_waktu: DateConvert(new Date()).default,
    id_karyawan: null,
    diskon: 0,
    ppn: 0,
    catatan: data?.keterangan_pengiriman ?? "",
    alamat_pengiriman: data?.lokasi_pengiriman ?? "",
    id_customer: data?.id_customer ?? "",
  };

  const validationSchema = yup.object().shape({
    tgl_sales_order: yup.date().required("Tgl. Sales Order wajib diisi"),
    no_sales_order: yup
      .string()
      .required("No. Sales Order wajib diisi")
      .nullable(),
    batas_waktu: yup.date().required("Tgl. Batas Waktu wajib diisi"),
    id_karyawan: yup.string().required("Sales wajib diisi").nullable(),
    // diskon: yup
    //   .number()
    //   .typeError("Format angka salah")
    //   .required("Sales wajib diisi")
    //   .nullable(),
    // ppn: yup
    //   .number()
    //   .typeError("Format angka salah")
    //   .required("PPn wajib diisi")
    //   .nullable(),
  });

  const handleSubmit = (values, { setSubmitting }) => {
    if (!checkGudang(dataDetail)) {
      setAlertConfig({
        show: true,
        variant: "danger",
        text: "List Item Barang Jadi belum memiliki Gudang",
      });

      return setSubmitting(false);
    }

    const Calculate = () => {
      const akumulasi = data.akumulasi;

      const subTotal = data?.detail?.map((res, i) => {
        // const totalProfit =
        //   res.unit_cost && profit
        //     ? parseInt(res.unit_cost) + (parseFloat(profit) / 100) * parseInt(res.unit_cost)
        //     : 0;
        const sum = parseInt(res.unit_cost) * parseFloat(res.qty_sales_order);

        return sum ?? [];
      });

      const total = subTotal
        ? subTotal.reduce((prev, curr) => prev + curr, 0)
        : 0;

      const totalSetelahDiskon =
        total - parseInt((total * values.diskon) / 100);
      const totalSetelahPajak =
        totalSetelahDiskon + parseInt((totalSetelahDiskon * values.ppn) / 100);

      const totalDenganAkumulasi =
        parseInt(totalSetelahPajak) + parseInt(akumulasi);

      return totalDenganAkumulasi;
    };

    if (Calculate() >= parseInt(data.kredit_limit)) {
      setKrditLimitAlertConfig({
        show: true,
        variant: "danger",
        text: "TRANSAKSI TIDAK DAPAT DISIMPAN KARENA MELEBIHI KREDIT LIMIT",
      });

      return setSubmitting(false);
    } else {
      TransaksiSalesOrderApi.createSalesOrder({
        ...values,
        detail: dataDetail,
      })
        .then(() => {
          history.push("/sls/transaksi/sales-order/rab", {
            registerAlertConfig: {
              variant: "primary",
              text: "Tambah Sales Order Berhasil",
            },
            ...location.state,
          });
        })
        .catch(() =>
          setAlertConfig({
            show: true,
            variant: "danger",
            text: "Tambah Sales Order Gagal",
          })
        )
        .finally(() => setSubmitting(false));
    }
  };

  if (!data) {
    return <DataStatus text="Data Gagal Dimuat" />;
  } else {
    return (
      <>
        <Alert
          show={alertConfig.show}
          showCloseButton={true}
          variant={alertConfig.variant}
          text={alertConfig.text}
          onClose={() =>
            setAlertConfig({
              ...alertConfig,
              show: false,
            })
          }
        />

        <div className="d-flex justify-content-between align-items-end mb-2">
          <h6 className="font-weight-bold">Tambah Data {title}</h6>
          <BackButton
            size="sm"
            onClick={() =>
              history.push("/sls/transaksi/sales-order/rab", location.state)
            }
          />
        </div>

        <Card>
          <Card.Body>
            <InfoPeluangSection data={data} />
            <InfoRABSection data={data} />
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {(formik) => (
                <form onSubmit={formik.handleSubmit}>
                  <FormSection formik={formik} />
                  <ListBarangJadiSection
                    dataDetail={dataDetail}
                    setDataDetail={setDataDetail}
                    formik={formik}
                    data={data}
                  />
                  <Alert
                    show={krditLimitAlertConfig.show}
                    showCloseButton={true}
                    variant={krditLimitAlertConfig.variant}
                    text={krditLimitAlertConfig.text}
                    onClose={() =>
                      setKrditLimitAlertConfig({
                        ...krditLimitAlertConfig,
                        show: false,
                      })
                    }
                  />
                  <div className="text-right">
                    <ActionButton
                      type="submit"
                      text="Simpan"
                      variant="primary"
                      loading={formik.isSubmitting}
                    />
                  </div>
                </form>
              )}
            </Formik>
          </Card.Body>
        </Card>
      </>
    );
  }
};

export default CreateSalesOrder;
