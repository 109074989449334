import { useFormik } from "formik";
import { DatePicker, SelectSearch, FilterModal } from "components";
import { DateConvert } from "utilities";

const ModalFilter = ({
  modalConfig,
  setModalConfig,
  data,
  setData,
  dropdownLoading,
  dropdownCustomer,
  dropdownBarangJadi,
  dropdownProyek,
  dropdownUnitProduksi,
}) => {
  const filter = data?.filter;

  const formInitialValues = {
    tanggal_start: filter.tanggal_start,
    tanggal_end: filter.tanggal_end,
    nama_customer: filter.nama_customer,
    nama_barang: filter.nama_barang,
    nama_proyek: filter.nama_proyek,
    id_unit_produksi: filter.id_unit_produksi,
  };

  const formSubmitHandler = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    if (checkActive) {
      setData((prev) => ({
        ...prev,
        filter: { ...values, active: true },
      }));
    } else {
      setData((prev) => ({ ...prev, filter: { ...values, active: false } }));
    }
    setModalConfig(false);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formInitialValues,
    onSubmit: formSubmitHandler,
  });

  const { values, setValues, setFieldValue, handleSubmit } = formik;

  const onTanggalTransaksiChange = (dates) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      tanggal_start: startDate,
      tanggal_end: endDate,
    });
  };

  const onResetButtonClick = () => {
    setData((prev) => ({
      ...prev,
      filter: {
        tanggal_start: undefined,
        tanggal_end: undefined,
        nama_customer: undefined,
        nama_barang: undefined,
        nama_proyek: undefined,
        id_unit_produksi: undefined,
      },
    }));

    setModalConfig(false);
  };

  return (
    <FilterModal
      show={modalConfig?.show}
      setShow={setModalConfig}
      onResetButtonClick={() => onResetButtonClick()}
      onFilterButtonClick={handleSubmit}
    >
      <DatePicker
        selectsRange
        monthsShown={2}
        label="Tgl. Transaksi"
        placeholderText="Pilih transaksi"
        dateFormat="dd/MM/yyyy"
        startDate={
          values?.tanggal_start ? new Date(values.tanggal_start) : null
        }
        endDate={values?.tanggal_end ? new Date(values.tanggal_end) : null}
        onChange={(selectedDate) => onTanggalTransaksiChange(selectedDate)}
      />

      <SelectSearch
        label="Customer"
        type="select"
        placeholder="Pilih salah satu..."
        name="nama_customer"
        defaultValue={{
          value: values?.nama_customer ?? undefined,
          label: values?.nama_customer ?? "Pilih salah satu...",
        }}
        option={dropdownCustomer}
        onChange={(e) => {
          setFieldValue("nama_customer", e.value);
        }}
        loading={dropdownLoading}
      />

      <SelectSearch
        label="Proyek"
        type="select"
        placeholder="Pilih salah satu..."
        name="id_proyek"
        defaultValue={{
          value: values?.nama_proyek ?? undefined,
          label: values?.nama_proyek ?? "Pilih salah satu...",
        }}
        option={dropdownProyek}
        onChange={(e) => {
          setFieldValue("nama_proyek", e.value);
        }}
        loading={dropdownLoading}
      />

      <SelectSearch
        label="Barang Jadi"
        type="select"
        placeholder="Pilih salah satu..."
        name="id_barang"
        defaultValue={{
          value: values?.nama_barang ?? undefined,
          label: values?.nama_barang ?? "Pilih salah satu...",
        }}
        option={dropdownBarangJadi}
        onChange={(e) => {
          setFieldValue("nama_barang", e.value);
        }}
        loading={dropdownLoading}
      />

      <SelectSearch
        label="Unit Produksi"
        type="select"
        placeholder="Pilih salah satu..."
        name="id_unit_produksi"
        defaultValue={
          values?.id_unit_produksi
            ? dropdownUnitProduksi.find(
                (val) => values?.id_unit_produksi === val?.value
              )
            : undefined
        }
        option={dropdownUnitProduksi}
        onChange={(e) => {
          setFieldValue("id_unit_produksi", e.value);
        }}
        loading={dropdownLoading}
      />
    </FilterModal>
  );
};

export default ModalFilter;
